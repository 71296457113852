
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
html, body{
    height: 100%;
}
body{
    min-height: 100%;
    font-family: $font-family-sans-serif;
}

.row.field {
    margin-top: 30px;
    margin-bottom: 30px;
}
